<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Expense</h1>
      </div>
      <ExpenseForm :viewMode="true" :eventName="'updateExpense'" @updateExpense="updateExpense" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import ExpenseForm from '@/components/financial/ExpenseForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Financial from '@/lib/financial';

export default {
  name: 'Expense',
  components: {
    ExpenseForm,
  },
  mounted(){
    this.loadExpense();
  },
  methods:{
    async loadExpense(){
      try{
        const loginInfo = Common.getLoginInfo();
        const expenseId = this.$route.params.id;
        const expense = await Financial.loadExpense(this.apiUrl, expenseId, loginInfo);
        this.setCurrentExpense(expense);
      }catch(err){
        console.log(err);
      }
    },
    async updateExpense(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const expenseId = this.$route.params.id;
        const expenseInfo = Object.assign({}, form);
        const date = dayjs(form.date).unix();
        expenseInfo.date = date;

        const employee = await this.getEmployeeByName(form.employeeId);
        const employeeId = employee.id;
        expenseInfo.employeeId = employeeId;

        const expense = await Financial.updateExpense(this.apiUrl, expenseId, expenseInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    ...mapActions(['setCurrentExpense']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
