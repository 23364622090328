<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled && this.form.status !== 'Accept'" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Employee information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Employee Name">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchEmployee"
            v-model="form.employeeId"
            placeholder="Input Employee Name"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <h2 class="card-title">Expense information</h2>
    <div class="card info-card">
      <el-form ref="expense-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Expense Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Date" class="field-date">
            <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="Select Date">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="Expense Type" style="max-width: 550px;">
          <el-select v-model="form.expenseType" placeholder="Select the expense type">
            <el-option
              v-for="item in expenseTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="other-expense-type" v-if="form.expenseType === 'Other'">
          <el-input v-model="otherExpenseType" placeholder="Please input the expense type"></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item v-if="viewMode === true && checkIsAdmin()"  label="Status" style="max-width: 550px;">
          <el-select v-model="form.status" placeholder="Select the status">
            <el-option label="Pending" value="Pending"></el-option>
            <el-option label="Accepted" value="Accept"></el-option>
            <el-option label="Rejected" value="Reject"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="Amount" style="max-width: 550px;">
          <el-input type="number" v-model="form.amount">
            <template slot="prepend">$</template>
          </el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="Reason" style="flex-basis: 59%;">
          <el-input
          type="textarea"
          :rows="3"
          v-model="form.reason">
        </el-input>
      </el-form-item>
    </div>
  </el-form>
</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'ExpenseForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      employeeId: '',
      name: '',
      date: '',
      expenseType: '',
      status: 'Pending',
      amount: 0,
      reason: '',
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      //For checking
      expenseTypes: [
        'Travel',
        'Hospitality',
        'Telephone Fee',
        'Freight',
        'Maintenance Fee',
        'Other',
      ],
      expenseTypeOptions:[
        {'label': 'Travel', 'value': 'Travel'},
        {'label': 'Hospitality', 'value': 'Hospitality'},
        {'label': 'Telephone Fee', 'value': 'Telephone Fee'},
        {'label': 'Freight', 'value': 'Freight'},
        {'label': 'Maintenance Fee', 'value': 'Maintenance Fee'},
        {'label': 'Other', 'value': 'Other'},
      ],
      otherExpenseType: '',
      form,
    };
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    startEdit(){
      this.disabled = false;
    },
    save(){
      if(this.form.expenseType === 'Other'){
        this.form.expenseType = this.otherExpenseType;
      }
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
    async searchEmployee(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for(let i = 0; i < employees.length; i++){
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }
      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  computed: mapState({
    currentUser: state => state.currentUser,
    currentExpense: state => state.financial.currentExpense,
    apiUrl: state => state.apiUrl,
  }),
  watch: {
    'currentExpense': function(){
      this.form = {
        employeeId: this.currentExpense.employee_name,
        name: this.currentExpense.expense_content,
        date: dayjs.unix(this.currentExpense.date),
        expenseType: this.currentExpense.expense_type,
        status: this.currentExpense.status,
        amount: this.currentExpense.amount,
        reason: this.currentExpense.reason,
      };

      if(!this.expenseTypes.includes(this.currentExpense.expense_type)){
        this.form.expenseType = 'Other';
        this.otherExpenseType = this.currentExpense.expense_type;
      }
    }
  }
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
  margin-bottom: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.other-expense-type{
  .el-form-item__content{
    margin-left: 30px !important;
  }
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
